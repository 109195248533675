body {
  background-color: #eeeeee;
  background-repeat: repeat;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  touch-action: none !important;
}

::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@-webkit-keyframes pulsate {
  50% { color: #fff; text-shadow: 0 -1px rgba(0,0,0,.3), 0 0 5px #ffd, 0 0 8px #fff; }
}

@keyframes pulsate {
  50% { color: #fff; text-shadow: 0 -1px rgba(0,0,0,.3), 0 0 5px #ffd, 0 0 8px #fff; }
}

.blink {
  color: rgb(245,245,245);
  text-shadow: 0 -1px rgba(0,0,0,.1);
  background: green;
  -webkit-animation: pulsate 1.2s linear infinite;
  animation: pulsate 1.2s linear infinite;
}

.MuiDialog-scrollPaper {
  touch-action: none !important;
}

